import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import he from 'he'

import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'

import Link from "../utils/link"
import { navigate } from "gatsby-link"

import "./news.scss"

const ResourcesPage = (props) => {

  React.useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
    if (window.location.hash === "#news") {
      const section = document.querySelector(".news-section")
      if (section) {
        window.scrollTo(0, section.offsetTop)
      }
    }
  }, [])

  const openServicePage = (e, service) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    navigate(`/resources/${service.slug}`)
  }

  const { page, posts } = useStaticQuery(graphql`
    query {
      page: wpPage(title: {eq: "Resources"}) {
        title
        slug
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphImage {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
      posts: allWpPost {
        edges {
          node {
            title
            slug
            date(formatString: "DD MMMM YYYY")
            acf {
              author
            }
          }
        }
      }
    }
  `) 
  return (
    <>
      <SEO title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
      <Header />
      <section className="news-section">
        <div name="news">
          <div className="content">
            <div className="container">
              <div className="news-section__links">
                {posts.edges.map((el, index) => (
                  <div key={index}>
                    <Link
                      to="#"
                      onClick={e => openServicePage(e, el.node)}
                      className="news-section__link"
                    >
                      {el.node.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ResourcesPage
